<template>
  <div class="topnavwrap" >
    <div class="cusnavbar"  ref="cusnavbar" :style="{boxShadow:isFixed?'0 0 24px rgba(0,0,0,.07)':'',background:!isFixed?'#fff0':'#fff'}">
    	<div class="navbarwrap"> 
			 <div class="navbar">
				<div :class="['logowrap',!isFixed ? 'lglogo' :'']" v-if="!iswindows">
						<span :style="{color:!isFixed?'#EB5C20':'#EB5C20'}">MUSKMOBI</span>
				</div>
				<div class="logowrap" v-if="iswindows">
						<span :style="{color:!isFixed?'#EB5C20':'#EB5C20',fontSize:'30px'}">MUSKMOBI</span>
				</div>
				<div class="navlistwrap" >
					<div class="nav_item"  @click="navto(1,'#section1')">
						<router-link to="#section1" :style="{color:!isFixed?'#fff':'#333',}" :class="1 == actiindex ? 'midline' : ''">
							<span>HOME</span>
						</router-link>
					</div>
					<div class="nav_item"  @click="navto(2,'#section2')">
						<router-link to="#section2" :style="{color:!isFixed?'#fff':'#333',}" :class="2 == actiindex ? 'midline' : ''">
							<span>SERVICE</span>
						</router-link>
					</div>
					<div class="nav_item"  @click="navto(3,'#section3')">
						<router-link to="#section3" :style="{color:!isFixed?'#fff':'#333',}" :class="3 == actiindex ? 'midline' : ''">
							<span>PRODUCT</span>
						</router-link>
					</div>
					<div class="nav_item"  @click="navto(4,'#section4')">
						<router-link to="#section4" :style="{color:!isFixed?'#fff':'#333',}" :class="4 == actiindex ? 'midline' : ''">
							<span>ABOUT</span>
						</router-link>
					</div>
					<div class="nav_item"  @click="navto(5,'#section5')">
						<router-link to="#section5" :style="{color:!isFixed?'#fff':'#333',}" :class="5 == actiindex ? 'midline' : ''">
							<span>PARTNERS</span>
						</router-link>
					</div>
					<div class="nav_item"  @click="navto(6,'#section6')">
						<router-link to="#section6" :style="{color:!isFixed?'#fff':'#333',}" :class="6 == actiindex ? 'midline' : ''">
							<span>CONTACT</span>
						</router-link>
					</div>
				</div>
				<div class="mobmenu rightmobmenu">
					<div class="nav_item" @click="loadmobmenu">
						<div :class="!isshowmobmenu?'menutransforicon':'revmenutransforicon'" :style="{color:!isshowmobmenu?--color-close:--color-primary}">
							<transition name="fade">
								<div v-if="!isshowmobmenu" :style="{borderBottom:!isshowmobmenu?'.02rem solid #eb5d2000':'.02rem solid #EB5C20'}" class="midline">
								   <img src="../assets/menu.png" alt="">
								</div>
							</transition>
						</div>
					</div>
				</div>
			 </div>
		 </div>
    </div>
	<transition name="slide-fade">
		<div class="submobmenu" :style="{background:!isFixed?'#434343ab':'#fff'}" v-if="isshowmobmenu">
			<div class="mobmenulist">
				<ul class="mobmenulistwrap">
					<li  @click="navto(1,'#section1')" :style="{color:!isFixed?'#fff':'#0000009e'}">
						<router-link to="#section1" :style="{color:!isFixed?'#fff':'#333',border:'none'}" :class="1 == actiindex ? 'midline' : ''">HOME</router-link>
					</li>
					<li  @click="navto(2,'#section2')" :style="{color:!isFixed?'#fff':'#0000009e'}">
						<router-link to="#section2" :style="{color:!isFixed?'#fff':'#333',border:'none'}" :class="2 == actiindex ? 'midline' : ''">SERVICE</router-link>
					</li>
					<li  @click="navto(3,'#section3')" :style="{color:!isFixed?'#fff':'#0000009e'}">
						<router-link to="#section3" :style="{color:!isFixed?'#fff':'#333',border:'none'}" :class="3 == actiindex ? 'midline' : ''">PRODUCT</router-link>
					</li>
					<li  @click="navto(4,'#section4')" :style="{color:!isFixed?'#fff':'#0000009e'}">
						<router-link to="#section4" :style="{color:!isFixed?'#fff':'#333',border:'none'}" :class="4 == actiindex ? 'midline' : ''">ABOUT</router-link>
					</li>
					<li  @click="navto(5,'#section5')" :style="{color:!isFixed?'#fff':'#0000009e'}">
						<router-link to="#section5" :style="{color:!isFixed?'#fff':'#333',border:'none'}" :class="5 == actiindex ? 'midline' : ''">PARTNERS</router-link>
					</li>
					<li  @click="navto(6,'#section6')" :style="{color:!isFixed?'#fff':'#0000009e'}">
						<router-link to="#section6" :style="{color:!isFixed?'#fff':'#333',border:'none'}" :class="6 == actiindex ? 'midline' : ''">CONTACT</router-link>
					</li>
				</ul>
			</div>
		</div>
	</transition>
  </div>
</template>

<script>
import {onMounted,onBeforeUnmount, ref} from 'vue';
import { useRouter,useRoute } from 'vue-router';
import bus from '../utils/eventbus'
const navlist = ['HOME','SERVICE','PRODUCT','ABOUT','PARTNERS','CONTACT']
export default {
  name: 'topNav',
  props: ['isFixed'],
  setup(props,context){
    let actiindex = ref('1');
	let isshowmobmenu = ref(false);
    const  router = useRouter();
	const route = useRoute();
	let iswindows = ref(navigator.userAgent.indexOf('Mobile') > '-1')
	function loadmobmenu(){
		isshowmobmenu.value = !isshowmobmenu.value;
	};
    function gousrcenter(){
      router.push({path:'',query:{}})
    };
	function navto(i,hash){
		actiindex.value = i;
		if(route.hash == hash){
			document.querySelector(hash).scrollIntoView({ behavior: 'smooth' })
		}
		router.beforeEach((to, from) => {
			if (to.hash) {
				const element = document.querySelector(to.hash);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
				}
			}
		});
	}
	onMounted(()=>{
		bus.on('emittergetsubRecipes', (data) => {
			actiindex.value = data+1
		// 处理事件的回调函数
		});

	})
	onBeforeUnmount( () => {
		bus.off('emittergetsubRecipes');
	})
    return{
		iswindows,
		isshowmobmenu,
		loadmobmenu,
		actiindex,
		navto,
		navlist,
		gousrcenter,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
	.fade-enter-active ,.fade-leave-active {
		transition:all 0.2s linear;
	}
	.fade-enter-from{
		transform: translateY(0px);
		opacity: 0.8;
	}
	.fade-leave-to {
		transform: translateY(0px);
		opacity: 0;
	}
	.slide-fade-enter-active ,.slide-fade-leave-active {
		transition:all 0.2s linear;
	}
	.slide-fade-enter-from{
		transform: translateY(-10px);
		opacity: 0.8;
	}
	.slide-fade-leave-to {
		transform: translateY(-10px);
		opacity: 0;
	}

    .topnavwrap{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 99100;
    }
    .cusnavbar{
		overflow: hidden;
    }
	.navbarwrap{
		width: 100%!important;
		max-width: var(--content-max-width,var(--breakpoint-xl));
		height: auto;
		text-align: center;
		margin: 0 auto;
    }
	.navbar{
		line-height: 105px;
		margin-right: var(--side-spacing);
		margin-left: var(--side-spacing);
		display: flex;
		justify-content: space-between;
		align-items: center;
		overflow: hidden;
	}
	.menutransforicon,.revmenutransforicon{
		width: 32px;
		height: .32rem;
		position: relative;
		top: 3px;
	}
	.menutransforicon::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: .02rem;
		background: var(--color-close);
		animation: menutransbefore .3s ease-in-out forwards;
	}
	.menutransforicon::after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: .02rem;
		background: var(--color-close);
		animation: menutransafter .3s ease-in-out forwards;
	}
	.revmenutransforicon::before{
		content: '';
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: .02rem;
		background: var(--color-primary);
		animation: revmenutransbefore .3s ease-in-out forwards;
	}
	.revmenutransforicon::after{
		content: '';
		position: absolute;
		bottom: 50%;
		left: 0;
		width: 100%;
		height: .02rem;
		background: var(--color-primary);
		animation: revmenutransafter .3s ease-in-out forwards;
	}    
	@keyframes  menutransbefore{
		0%{top:50%;transform:translateY(0px) rotate(45deg);}
		50%{top:50%;transform:translateY(0px) rotate(0deg);}
		100%{top:0;transform:translateY(0px) rotate(0deg);}
	}
	@keyframes  menutransafter{
		0%{bottom: 50%;transform:translateY(0px) rotate(-45deg);}
		0%{bottom: 50%;transform:translateY(0px) rotate(0deg);}
		100%{bottom: 0%;transform: translateY(0px) rotate(0deg);}
	}
    @keyframes  revmenutransbefore{
		0%{top:0%;transform:translateY(0px) rotate(0deg);}
		50%{top:45%;transform:translateY(0px) rotate(0deg);}
		100%{top:45%;transform:translateY(0px) rotate(45deg);}
	}
	@keyframes  revmenutransafter{
		0%{bottom: 50%;transform:translateY(0px) rotate(0deg);}
		0%{bottom: 50%;transform:translateY(0px) rotate(0deg);}
		100%{bottom: 50%;transform: translateY(0px) rotate(-45deg);}
	}
	.midline{
		color: var(--color-primary)!important;
		border-bottom:.02rem solid var(--color-primary);
		padding: 0 0 10px 0;
		height: 32px;
	}
	.midline img{
        width: 100%;
		margin: -85px 0 0 0;
	}
	.logowrap{
		font-size: 40px;
		width: var(--custom-logo-width,18rem);
		transition:all .2s ease-in-out ;
	}
	.logowrap span{
		text-transform: uppercase;
		cursor: pointer;
	}
	.scal-enter-active,.scal-leave-active{
		transition: all .3s ease-in-out;
	}
	.scal-enter-from,.scal-leave-to{
		width: 160px!important;
	}
	.scallg-enter-active,.scallg-leave-active{
		transition: all .3s ease-in-out;
	}
	.scallg-enter-from,.scallg-leave-to{
		width: 230px!important;
	}
	.lglogo{
       width: 230px;
	   transition: all .3s ease-in-out ;
	}	
	.mobmenu{
		/* overflow: hidden; */
		font-size: .2rem;
		display: var(--custom-visibility-flex-menu);
		transition:all .2s ease-in-out ;				
	}
	.mobmenu view{
		float: left;
	}

	.navlistwrap{
		display: var(--custom-visibility-flex);
		justify-content: space-around;
		align-items: center;
		font-size: .2rem;
		margin: 0 0 0 6vw;
		flex: 1;
      }
      .nav_item{
        cursor: pointer;
		text-transform: uppercase;
      }

	.rightmobmenu .nav_item{
		float: left;
	}   
	.rightmobmenu .nav_item:nth-child(1){
		/* background: var(--color-primary); */
		color: #fff;
		padding: 3px 5px;
		text-transform: capitalize;
		font-weight: var(--body-font-weight-bold,unset);
		border-radius: 5px;
	}
	.rightmobmenu .nav_item:nth-child(2){
		margin-left: var(--spacing-lg);
	}
	.navlistwrap	span{
		cursor: pointer;
		font-size: var(--font-size-md);
		text-transform: uppercase;
	}
	.submobmenu{
		max-height: calc(100vh - var(--header-height, 0px) - var(--header-shrink-offset));
		overflow-y: scroll;
		position: absolute;
		top:100%;
		left: 0;
        right:0 ;
		box-shadow: 0 3px 8px #fff;
		padding: 5px 0 0 0;
		z-index: 5;
		display: var(--custom-visibility-flex-menu );
	}
	.mobmenulist {
		margin-right: var(--side-spacing);
    	margin-left: var(--side-spacing);

	}
	.mobmenulist .compico{
		display: flex;
		flex-wrap: nowrap;
		gap: 5px;
		align-items: center;
		justify-content: start;
		margin: 0 0 5px 0;
	}
	.mobmenulist .compico img{
		width: 30px;
		height: 30px;
		margin: 0;
		background: #4b2970;
		padding: 2px;
		border-radius: 5px;
	}
	.searcheara input{
		border: 1px solid rgba(0,0,0,.1);
		width: 100%;
		font-size: var(--font-size-md);
		-webkit-appearance: none;
		padding: 15px;
	}
	.mobmenulistwrap{
		width: 100%;
		padding: 0;
	}
	.mobmenulistwrap li{
		text-align: left;
		line-height: 0.7;
		padding: var(--menu-item-link-padding,var(--spacing-md) var(--spacing-md));
		font-weight: 400;
		font-size: var(--font-size-md);;
		font-family: var(--menu-font-family,var(--inherit));
		text-transform: capitalize;
	}
	.mobmenulistwrap li:active{
		font-weight: 600;
	}
</style>
